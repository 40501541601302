.geo-eg-date {
  font-size: 12px;
  color: rgb(7, 36, 22);
  font-weight: 300;
  letter-spacing: 1px;
  margin-top: -10px;
  margin-bottom: 32px;
}
.selfie-btns {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.dv-album-container {
  position: relative;
  border-bottom: 1px solid #ededed;
  padding-bottom: 20px;
}

.checkout.ns a {
  display: flex;
}
.checkout.ns .secondary-btn:focus {
  color: #000;
}

.checkout.ns a svg {
  width: 16px;
  height: 13px;
}
.checkout.ns a.disable-btn svg,
.checkout.ns a.disable-btn path {
  fill: black;
}
.checkout.ns a.disable-btn span {
  color: rgb(33, 29, 29) !important;
}

.dv-album-photos {
  margin-bottom: 10px;
  overflow: auto;
}

.dv-section-footer.expanded {
  position: sticky;
  bottom: 10px;
  padding: 0;
}

.dv-section-footer.collapsed {
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
}

.dv-section-footer a {
  display: inline-block;
  box-shadow: 0px 5px 25px rgba(166, 166, 166, 0.5);
  border-radius: 100px;
  background: #34b2a7;
  color: white;
  letter-spacing: 1px;
  font-weight: 400;
  padding: 5px 20px;
  margin: 0 auto;
  left: 0;
  right: 0;
  -webkit-transition: background-color 300ms linear, color 100ms linear;
  -ms-transition: background-color 300ms linear, color 100ms linear;
  transition: background-color 300ms linear, color 100ms linear;
}

.dv-section-footer.dv-section-footer.expanded a {
  box-shadow: 0px 5px 25px rgba(0, 0, 0, 0.65);
}

.dv-album-photos.collapsed {
  max-height: 450px;
  overflow: hidden;
  position: relative;
}

.dv-album-photos.collapsed::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 30px;
  background: -webkit-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: -moz-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: -o-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
  background-image: -ms-linear-gradient(
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.geo-private-wrap {
  position: relative;
  margin: auto;
  text-align: center;
  border-radius: 10px;
  padding: 20px;
}
.geo-private-lock {
  display: block;
  margin: auto;
  margin-bottom: 20px;
}
.ns .geo-private-head {
  font-weight: 800;
  font-size: 18px;
  line-height: 13px;
  text-align: center;
  color: #30053f;
}
.geo-private-desc {
  font-size: 13px;
  color: #8b7f8f;
  line-height: 1.6;
  margin-bottom: 25px;
}
.geo-private-btn {
  border-radius: 25px;
  box-shadow: none !important;
}
.geo-pvt-input-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.geo-private-wrap .selfie-up-btn {
  margin: 0;
  margin-bottom: 15px;
  width: auto;
  height: 45px;
  padding: 14px !important;
  box-shadow: none !important;
}
.geo-private-wrap .webcam-component,
.geo-pvt-input-wrap .btn {
  box-shadow: none;
}
.btn-take-selfie span,
.geo-private-wrap .selfie-up-btn span {
  display: inline-block;
  vertical-align: middle;
}
.btn-take-selfie img {
  height: 18px;
  margin-right: 9px;
  filter: grayscale(100) brightness(100);
}
.geo-private-wrap .filter-selfie-img-div {
  margin: auto;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  overflow: hidden;
  border: 2px dashed #30053f;
  background: #eff5f6;
}
.geo-private-wrap .filter-selfie-img-div .bit-m {
  width: 100px;
  height: auto;
  margin-top: 0;
}
.geo-private-wrap:not(.gpw-m) .ns-modal .close {
  top: 0;
}
.geo-private-wrap .ns-modal-large .filter-dialog-content {
  max-width: 600px;
}

/* .geo-protected-fw {
  width: 100%;
  float: none;
} */
.dv-results.geo-protected-fw .input-group {
  display: block;
  width: auto;
}

.ns .geo-pvt-input-wrap .form-control {
  height: 45px;
  margin-bottom: 15px;
  width: 50%;
}
.geo-pvt-input-wrap .btn {
  margin-bottom: 15px;
}
.dv-results.geo-protected-fw .help-block {
  margin-bottom: 15px;
  margin-top: -10px;
}
.geo-private-wrap-outer {
  margin: 0px 13px;
  padding: 20px;
  background-color: inherit;
}
.geo-private-wrap-outer:first-child,
.geo-protected-fw:first-child {
  width: 100%;
  float: none;
}
.geo-width {
  max-width: 420px !important;
}
.disable-change-selfie {
  pointer-events: none;
}
.dv-filterhead {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 5px 10px 15px;
}
.dv-filterby {
  display: flex;
  align-items: center;
}
.dv-filterby {
  display: flex;
  align-items: center;
}
.dv-sidebar {
  position: sticky;
  position: -webkit-sticky;
  top: 70px;
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 16px;
  border-right: 1px solid rgb(228, 228, 228);
  z-index: 10;
}
.dv-filter-wrap {
  position: sticky;
  position: -webkit-sticky;
  top: 70px;
}
.trans-banner-active .dv-sidebar,
.gtrans-active .dv-sidebar,
.trans-banner-active .dv-filter-wrap,
.gtrans-active .dv-filter-wrap {
  top: 110px;
}
.dv-filter-wrap {
  max-height: calc(100vh - 67px);
  overflow-x: hidden;
  overflow-y: auto;
}
.trans-banner-active .dv-filter-wrap {
  max-height: calc(100vh - (67px + 40px));
}
.modal-open .dv-filter-wrap {
  overflow: visible;
}

.filter-block {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 12px 0px 5px 0px;
  cursor: pointer;
  border-bottom: 1px solid rgb(228, 228, 228);
  height: 60px;
}
.filter-icon {
  width: 15px;
  position: relative;
}
.dv-filter-wrap .filter-icon {
  margin-right: 8px;
}
.filter-block > .active-icon {
  position: absolute;
  margin-top: -24px;
  margin-left: 10px;
  z-index: 1;
}
.filter-text {
  font-size: 0.63em;
  color: #8b7f8f;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: capitalize;
  text-align: center;
}
.toggleIn-img {
  margin-right: -24px;
  width: 36px;
  cursor: pointer;
  margin-top: -2.7px;
}
.dv-filtersIcon {
  display: flex;
  justify-content: center;
  padding: 12px 0px 5px 0px;
  border-top: 1px solid rgb(228, 228, 228);
  border-bottom: 1px solid rgb(228, 228, 228);
  cursor: pointer;
}
.dv-filtertoggle {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3px;
}
.toggleOut-icon {
  width: 30px;
  cursor: pointer;
  z-index: 100;
  position: absolute;
  right: -13px;
}
.filter-border {
  border-bottom: 1px solid #dbdbdb;
}
.filter-border .collapse.in {
  height: auto !important;
}
.filter-border ul.check-radio .r-desc {
  margin-top: 0;
}
.filter-head-border {
  border-bottom: 1px solid #dbdbdb;
  border-top: 1px solid #dbdbdb;
}
.filter-block:hover {
  background-color: #eaf1f2;
}
.dv-filtersIcon:hover {
  background-color: #eaf1f2;
}
.offer-banner-heading {
  cursor: pointer;
  display: flex;
}
.offer--multi-tier .banner-text {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 10px;
}
.offer--multi-tier hr {
  margin: 5px 0;
  border-color: #fff;
  opacity: 0.3;
}
.play-icon {
  position: absolute;
  width: 62px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
}
.time-stamp {
  position: absolute;
  right: auto;
  left: 8px;
  position: absolute;
  background-color: rgb(0 0 0 / 75%);
  height: fit-content;
  font-size: 11px;
  color: white;
  border-radius: 3px;
  padding: 9px 7px;
  line-height: 0;
  text-align: center;
  font-weight: 600;
  top: auto;
  bottom: 8px;
  z-index: 40;
}

#copyIconDesktop svg {
  background-color: #4f2b5d;
}

.video-js > .vjs-big-play-button {
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  top: 0;
  bottom: 0;
}
.video-js > .vjs-big-play-button .vjs-icon-placeholder:before {
  line-height: 49px;
}
.video-js .vjs-fullscreen-control {
  display: none;
}
.video-js .vjs-picture-in-picture-control {
  display: none;
}
.tag-heading {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 10px;
}

.basic-tag {
  margin: 2px;
  font-weight: 600;
  padding: 2px 7px;
  border: 1px solid #d9d9d9;
  font-size: 13px;
  color: #8b7f8f;
  border-radius: 3px;
}
.tags-list .tag-span {
  border-radius: 5px;

  margin: 5px 5px 0px 0px;
  padding: 0px 6px;
  font-size: 13px;
  font-weight: 600;
}
.tags-list .photo-tag {
  background-color: #eff5f6;
  color: #259fa5;
}
.tags-list .album-tag {
  background-color: #fcf5fe;
  color: #ff0072;
  border: none;
}
.photo-tags-div {
  padding: 20px;
  border-top: 1px solid #cfcfcf;
}
.egm .photo-tags-div {
  padding: 20px 0;
  margin-top: 15px;
}
.save-div {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  align-items: center;
}
.multi-select-input {
  margin-top: 20px;
}
.multi-select-input .input-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.input-div .input-label {
  font-size: 13px;
  margin-bottom: 8px;
}
.input-div input {
  height: 38px;
  border: none;
  background-color: #fcf5fe;
  width: 100%;
  border-radius: 23px;
  padding: 0px 18px;
  margin-bottom: 14px;
}
.input-div input,
input::placeholder {
  font-size: 14px;
}
.tags-list {
  display: flex;
  flex-wrap: wrap;
}
.tag-heading .title {
  color: #8b7f8f;
  font-weight: 700;
  font-size: 13px;
}
.tag-heading .add-edit-btn {
  color: #259fa5;
  font-weight: 700;
  font-size: 13px;
  cursor: pointer;
}
.add-tags-btn {
  height: fit-content;
  padding: 9px 27px;
  font-size: 13px;
}
#hide-btn {
  display: flex;
  margin-left: 4px;
  margin-top: 2px;
}
#hide-btn button {
  padding: 0px;
}
.geo-tag-b__actions {
  display: flex;
  align-items: center;
}
#hide-tooltip .tooltip-inner {
  max-width: 240px;
}
.tag-mode-tog {
  padding: 0px 15px;
  background-color: #eff5f6;
  border-radius: 35px;
  display: flex;
  align-items: center;
  height: 45px;
}
.tag-mode-tog .geo-form__tog {
  margin-right: 0;
}
.geo-form__tog {
  min-width: 45px;
  height: 26px;
  width: 45px;
}
.geo-form__tog-in {
  width: 17px;
}
.geo-tog-content {
  display: flex;
  justify-content: space-between;
}

.geo-content-btn {
  display: flex;
}
.apply-tag-btn {
  margin: 0px;
}
.switch.geo-form__toggle .geo-form__tog {
  order: 0;
}
.icons-div-action {
  display: flex;
  align-items: center;
}
.sidebar-icons-div {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 80px;
  align-items: center;
  width: 100%;
}
.sidebar-icons-div img:first-child {
  margin-bottom: 12px;
  width: 22px;
}
.sidebar-icons-div img {
  width: 18px;
  margin: 6px 0px;
}

.fake-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 65px;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  border-right: 1px solid #cfcfcf;
  transition: width 0.3s ease;
  cursor: pointer;
}

.fake-sidebar.open {
  width: 300px;
}

.arrow-circle {
  position: absolute;
  top: 84px;
  right: -14px;
  z-index: 1000;
  cursor: pointer;
  width: 28px;
  height: 28px;
  background-color: white;
  border-radius: 28px;
  border: 1px solid #cfcfcf;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0px 0px 20px -8px rgba(0, 0, 0, 0.93);
  -moz-box-shadow: 0px 0px 20px -8px rgba(0, 0, 0, 0.93);
  box-shadow: 0px 0px 20px -8px rgba(0, 0, 0, 0.93);
}

.arrow-btn {
  width: 8px;
}

.fake-sidebar.closed .arrow-circle {
  right: -14px;
}

.fake-sidebar.open .arrow-circle {
  right: -14px;
}
.fake-sidebar .tooltip-inner {
  white-space: nowrap;
  min-width: fit-content;
  max-width: 165px;
}

@media (max-width: 1360px) {
  .fake-sidebar {
    width: 65px;
    background: #fff;
    z-index: 999;
  }
  .fake-sidebar.open {
    z-index: auto;
  }
  #desktop-version:has(.fake-sidebar) {
    width: 95%;
    margin: auto;
  }

  .fake-sidebar.open {
    width: 280px;
  }
  .fake-sidebar.closed .arrow-circle {
    right: -14px;
  }

  .fake-sidebar.open .arrow-circle {
    right: -33px;
  }
}

@media (max-width: 1260px) and (min-width: 1200px) {
  #desktop-version:has(.fake-sidebar) {
    width: 90%;
  }
}

@media (max-width: 1200px) {
  .fake-sidebar.open {
    width: 300px;
  }
  .fake-sidebar.open .arrow-circle {
    right: -14px;
  }
}

@media (max-width: 1060px) and (min-width: 991px) {
  #desktop-version:has(.fake-sidebar) {
    width: 85%;
  }
}

@media (min-width: 991px) {
  body {
    overflow: visible;
  }
}
@media (max-width: 991px) {
  .egm .photo-tags-div {
    margin-top: 0px;
  }
  .geo-private-wrap-outer {
    margin: 30px 0px;
    background: #fff;
    margin: -12px -15px 0;
    padding: 20px;
  }
  .geo-private-wrap {
    background-color: #eff5f6;
  }
}
