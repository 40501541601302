/* Full Size Variant */

.collect-customer-email {
  width: 85%;
  margin: 120px auto;
}

.collect-customer-email h1 {
  letter-spacing: inherit;
  color: #0c0c0c;
  font-size: 18px;
  margin-bottom: 8px;
}

.collect-customer-email h2 {
  font-size: 16px;
  margin-top: 14px;
  margin-bottom: 16px;
}

.collect-customer-email .input-group {
  width: 400px;
  margin: 0 auto;
}

.collect-customer-email-done {
  color: #2bb2a7;
  font-weight: 800;
  font-size: 14px;
  width: 245px;
  line-height: 1.4;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid #ddd;
}
.collect-customer-email-banner .collect-customer-email-done {
  text-align: right;
  margin-right: 0;
}

@media (max-width: 600px) {
  .dv-results .dv-event-no-albums {
    margin: 0 auto;
  }
  .collect-customer-email {
    margin: 50px auto;
  }

  .collect-customer-email .input-group {
    width: 100%;
  }
}

/* Banner Variant */

.collect-customer-email-banner {
  border-radius: 5px;
  background-color: #f7fbfd;
  margin-top: 10px;
  display: flex;
  align-items: center;
  padding: 10px 0;
}

.collect-customer-email-banner h1 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 800;
  letter-spacing: normal;
  text-align: left;
  color: #000;
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 0;
}

.collect-customer-email-text {
  padding-left: 15px;
  width: 40%;
}

.collect-customer-email-banner p {
  font-size: 14px;
  color: #343434;
  margin-bottom: 2px;
  line-height: 18px;
}

.collect-customer-email-banner img {
  margin-left: -5px;
  margin-top: -5px;
  height: 62px;
}

.collect-customer-email-banner .fa {
  /* position: absolute;
  right: 0px;
  top: 20px; */
  /* margin-left: auto; */
  padding: 5px 15px;
  margin-right: 5px;
  color: grey;
  font-size: 20px;
  z-index: 1;
  cursor: pointer;
}

.collect-customer-email-fields {
  margin-left: auto;
}

.collect-customer-email-banner .notify-button {
  margin-top: 0;
  margin-left: auto;
}
.collect-customer-email-banner .geo-btn {
  margin-left: 5px;
  white-space: nowrap;
}

.dv-event-no-albums-banner {
  overflow: hidden;
  padding-right: 15px;
  position: relative;
  max-height: 800px;
  transition: max-height 500ms;
}

.dv-event-no-albums-banner.collapsed {
  max-height: 0;
}

@media (max-width: 600px) {
  .collect-customer-email-banner {
    padding: 10px;
    margin-top: 0;
  }

  .collect-customer-email-banner h1 {
    margin-top: 0;
  }

  .collect-customer-email-banner .notify-button .btn {
    width: 100%;
  }
}
